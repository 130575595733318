.text-color-footer {
    color: white;
}

.btn-suscribete-cliente {
    background-color: #C99D66 !important;
    color: #FFF;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: calc(0.7rem);
    padding-bottom: calc(0.54rem);
}